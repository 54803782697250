.footer {
  background-color: #114084; // Main background color
  color: #F1F1F1;
  padding: 10px 2rem;
  font-family: 'Arial', sans-serif;
  max-width: 100%;
  overflow-x: hidden;
  /* Ensure no horizontal overflow */

  .footer-top {
    text-align: center;
    margin-bottom: 2rem;

    .back-to-top {
      color: #82b7dc;
      font-size: 1.2rem;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footer-columns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    .footer-column {
      flex: 1;
      padding: 1rem;

      h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        color: #F1F1F1;
      }

      p,
      ul,
      li,
      a {
        color: #F1F1F1;
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          color: #82b7dc; // Hover effect
        }
      }

      .subscribe-form {
        display: flex;
        flex-direction: row;

        input {
          padding: 0.5rem;
          border: none;
          border-radius: 4px 0 0 4px;
          width: 200px;
        }

        button {
          padding: 0.5rem;
          background-color: #3466aa;
          border: none;
          border-radius: 0 4px 4px 0;
          color: #F1F1F1;
          cursor: pointer;

          &:hover {
            background-color: #82b7dc;
          }
        }
      }
    }
  }

  .footer-bottom {
    text-align: center;
    padding: 1rem;
    background-color: #3466aa;

    .social-links {
      margin-bottom: 1rem;

      .social-icons {
        width: 40px;
        height: 40px;
        margin: 0 10px;
        color: #F1F1F1;
        text-decoration: none;
      }

    }
  }

  // Responsive styles
  @media (max-width: 768px) {
    .footer-columns {
      flex-direction: column; // Stack columns vertically on smaller screens
      text-align: center;
    }

    .footer-column {
      margin-bottom: 1.5rem;
    }

    .subscribe-form {
      flex-direction: column;

      input {
        width: 100%; // Full width on mobile
        margin-bottom: 1rem;
        border-radius: 4px; // Reset border radius for single input
      }

      button {
        width: 100%; // Full width on mobile
        border-radius: 4px; // Reset border radius for single button
      }
    }

    .social-links {
      a {
        display: block;
        margin: 5px 0; // Stack social links vertically on smaller screens
      }
    }
  }

  @media (max-width: 480px) {
    .footer-top {
      font-size: 1rem;

      .back-to-top {
        font-size: 1rem; // Reduce font size for smaller screens
      }
    }

    .footer-columns {
      margin-bottom: 1rem;
    }

    .footer-bottom {
      font-size: 0.875rem;
    }
  }
}