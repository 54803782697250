.add-product-form {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
  }
  
  .add-product-form h2 {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  .varform {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .image-upload-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .main-image-preview {
    width: 100%;
    height: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background-color: #f9f9f9;
  }
  
  .main-image-preview img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
  }
  
  .thumbnail-previews {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .thumbnail-wrapper {
    position: relative;
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
  }
  
  .thumbnail-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .thumbnail-wrapper .remove-image {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 0 5px 0 5px;
    padding: 2px 5px;
    cursor: pointer;
  }
  
  .thumbnail-wrapper .remove-image:hover {
    background-color: rgba(255, 0, 0, 0.7);
  }
  
  .add-image-button label {
    display: block;
    background-color: #333;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-image-button label:hover {
    background-color: #555;
  }
  
  .add-image-button input {
    display: none;
  }
  .product-details {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .product-details label {
    display: flex;
    flex-direction: column;
    font-family: 'Arial', sans-serif;
    color: #555;
  }
  
  .product-details input,
  .product-details select,
  .product-details textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Arial', sans-serif;
    width: 90%;
  }
  
  .product-details textarea {
    height: 100px; /* Fixed height */
    max-height: 100px;
    overflow-y: auto; /* Adds vertical scroll if content exceeds height */
    resize: none; /* Optional: Prevents manual resizing of the textarea */
  }
  
  .product-details button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: white;
    font-family: 'Arial', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .product-details button:hover {
    background-color: #555;
  }  

  .dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    background-color: #333;
  }

  .input-container {
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .input-with-icon {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .input-with-icon .icon {
    position: absolute;
    left: 10px;
    width: 20px;
    height: 20px;
  }

  .input-with-icon img {
    width: 40px !important; /* Adjust to desired size */
    height: 30px !important; /* Adjust to desired size */
    position: absolute;
    left: 10px; /* Adjust to position it properly */
    top: 50%;
    transform: translateY(-50%);
  }
  
  .input-field {
    padding-left: 50px !important; /* Adjust this padding to make room for the icon */
    width: 440px !important;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .input-field:focus {
    border-color: #888;
    outline: none;
  }