.buy-button-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  .shopee-button {
    background-color: #3466aa;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    font-size: 1.2rem;
    text-decoration: none;
    transition: background-color 0.3s ease;
    margin-right: 1rem;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:hover {
      background-color: #82b7dc;
    }

    svg {
      font-size: 1.5rem;
    }
  }

  .more-platforms-container {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    .more-platforms-icon {
      font-size: 2rem;
      color: #3466aa;
      transition: transform 0.3s ease;
      position: relative;
      top: -1px;
    }

    .more-text {
      font-size: 1rem;
      color: #1c1c1c;
      margin-left: 0.5rem;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 2px;
        background-color: #1c1c1c;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.4s ease;
      }

      &:hover::after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }

    .menu {
      list-style: none;
      position: absolute;
      top: 0;
      left: 95%;
      transform: translate(-50%, -50%);
      margin: 0;
      padding: 0;
      pointer-events: none;

      li {
        position: absolute;
        width: 45px;
        height: 45px;
        opacity: 0;
        transform: translate(0, 0);
        z-index: 1000; // Add a higher z-index for the menu
        transition: transform 0.5s ease, opacity 0.5s ease;

        .platform-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45px;
          height: 45px;
          background: #82b7dc;
          color: #fff;
          font-size: 1.5rem;
          transition: background-color 0.3s ease, transform 0.3s ease;
          text-decoration: none;

          &:hover {
            background-color: #3466aa;
            transform: scale(1.1);
          }

          svg {
            width: 24px;
            height: 24px;
            fill: currentColor;
          }

          .platform-icon.disabled {
            pointer-events: none;
            opacity: 0.5;
          }

          .shopee-button.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }

        /* Tooltip */
        .tooltip-menu {
          position: relative;
          display: inline-block;


          .tooltiptext-menu {
            visibility: hidden;
            opacity: 0;
            width: 120px;
            background-color: black;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            z-index: 1500;

            position: absolute;
            bottom: 150%;
            left: 50%;
            transform: translateX(-50%);
            transition: visibility 0s, opacity 0.3s ease-in-out;

            /* Arrow for tooltip */
            &::after {
              content: '';
              position: absolute;
              top: 100%;
              /* Pointing down */
              left: 50%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
            }
          }

          &:hover .tooltiptext-menu {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }


    /* When the menu is open */
    &.open .menu {
      pointer-events: auto;
    }

    &.open .menu li {
      opacity: 1;
    }

    /* Positions for each icon */
    &.open .menu li:nth-child(1) {
      transform: translate(-60px, -60px);
      /* Top-left */
      transition-delay: 0s;
    }

    &.open .menu li:nth-child(2) {
      transform: translate(60px, -60px);
      /* Top-right */
      transition-delay: 0.1s;
    }

    &.open .menu li:nth-child(3) {
      transform: translate(0, -90px);
      /* Directly above */
      transition-delay: 0.2s;
    }
  }
}