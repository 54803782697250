.dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.navbar_dashboard {
  flex: 0 0 auto; /* Make sure the navbar doesn't shrink or grow */
}

.main-content {
  display: grid;
  grid-template-columns: 200px 1fr; /* Left side 200px, right side takes the remaining space */
  flex: 1 1 auto; /* Make the main content take the remaining space */
  height: 100%; /* Full height */
  padding-top: 60px; /* Add padding to avoid content being hidden behind the navbar */
}


.menu {
  width: 200px;
  background-color: #333;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  color: #ddd;
}

.menu ul {
  list-style-type: none;
  padding: 0;
}

.menu li {
  padding: 10px;
  cursor: pointer;
  position: relative;
}

.menu li:hover {
  background-color: #1d1d1d;
}

.menu li.open > .submenu {
  max-height: 100%; /* Adjust based on submenu items */
  opacity: 1;
  transform: translateY(0);
  padding: 5px 0;
}

.submenu {
  max-height: 0;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  opacity: 0;
  transform: translateX(20px);
  background-color: #1d1d1d; /* Different background color */
  border-left: 2px solid #898989;
  padding-left: 15px;
  margin-left: 10px;
}

.submenu li {
  padding: 5px 10px;
  font-size: 0.9em; /* Smaller font size for submenu */
}

.submenu li:hover {
  background-color: #2d2d2d;
}

/*nav bar*/
.navbar_dashboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #333;
  color: white;
  padding: 20px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.logo {
  font-size: 1.5em;
  flex: 1;
}

.search-box {
  flex: 2;
  display: flex;
  justify-content: center;
}

.search-box input {
  width: 100%;
  max-width: 400px;
  padding: 5px;
  border: none;
  border-radius: 5px;
}

.user {
  position: relative;
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  right: 5%;
}

.user-name {
  font-size: 1em;
}

.user-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  color: black;
  width: 200px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.3s ease;
  visibility: hidden;
}

.user-menu.open {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.user-menu hr {
  margin: 10px 0;
  border: 0.5px solid #ddd;
}

.user-menu button {
  width: 100%;
  padding: 5px;
  border: none;
  background: #333;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.user-menu button:hover {
  background: hotpink;
}