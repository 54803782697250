.card-container {
  display: flex;
  flex-direction: column;
  /* Stack card and info vertically */
  /* Center align the card and info */
  margin: 1rem;
  max-width: 400px;
  /* Ensure the card container has a consistent width */
}

.card {
  position: relative;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 20rem;
  height: 28rem;
  /* Adjust height according to your preference */

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }



  /* Skeleton loading */
  .skeleton {
    width: 100%;
    height: 100%;
    background: linear-gradient(-90deg, #e0e0e0 25%, #f1f1f1 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
}

/* Card Info (Title, Price, and Colors) */
.card-info {
  text-align: left;

  .card-title {
    font-size: 1.5rem;
    color: #1c1c1c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    /* You can adjust this as needed */
    display: inline-block;
  }

  .price-info {
    height: 50px;
    /* Adjust to ensure consistent height for all cards */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }

  .card-price {
    font-size: 1.2rem;
    color: #808080;
    margin-top: -5%;
    white-space: nowrap;
  }

  .sale-tag {
    color: red;
    font-size: 1rem;
    margin-left: 5px;
  }

  .sale-price {
    font-size: 1.5rem;
    color: #1c1c1c;
    margin-top: -15%;
  }

  .color-options {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 0.5rem;

    .color-dot {
      cursor: pointer;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
      border: 1px solid #b4b4b4;
      transition: transform 0.3s ease, border 0.3s ease, box-shadow 0.3s ease;
      box-shadow: 0 0 0 0 transparent;
      /* Initial box-shadow */
      &:hover {
        transform: scale(1.2);
        border: 0.5px solid #e0e0e0;
        /* Keep the original border */
        box-shadow: 0 0 0 2px #032B44;
        /* Add a deep blue ring around the dot */
      }
    }



    .color-more {
      font-size: 0.8rem;
      color: #808080;
    }
  }
}

@media (max-width: 480px) {
  .card-container {
    max-width: 250px;
    /* Further reduce the card width on extra small screens */
  }

  .card-info {
    .card-title {
      font-size: 1.1rem;
      /* Further reduce font size */
    }

    .price-info {
      .card-price {
        margin-top: 15%;
        font-size: 0.9rem;
        /* Further adjust font size */
      }

      .sale-price {
        font-size: 1.2rem;
        /* Adjust sale price font size */
      }
    }

    .color-options {
      .color-dot {
        width: 12px;
        height: 12px;
        /* Further reduce size of color dots on extra small screens */
      }
    }
  }
}