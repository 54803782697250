.slideshow-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 2rem 2rem; /* Add padding to the left and right for button space */
  box-sizing: border-box; /* Ensure padding doesn't affect overall width */
}

.product-slider {
  display: flex;
  gap: 3.5rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scrollbar-width: none; /* Hide scrollbar on Firefox */
  user-select: none;
  -ms-overflow-style: none; /* Hide scrollbar on IE */
}

.product-slider::-webkit-scrollbar {
  display: none; /* Hide scrollbar on Chrome */
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(17, 64, 132, 0.5);
  color: white;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 2rem;
  z-index: 10;
}

.scroll-button.left {
  left: 10px; /* Adjust positioning for visibility */
}

.scroll-button.right {
  right: 10px; /* Adjust positioning for visibility */
}

.scroll-button:hover {
  background-color: rgba(52, 102, 170, 0.8);
}

.card-container {
  scroll-snap-align: center;
  min-width: 20%; /* Adjust this based on screen size */
  flex: 0 0 20%; /* Default card width */

  @media (max-width: 1024px) {
    min-width: 45%; /* For tablets, adjust to show fewer cards */
    flex: 0 0 45%;
  }

  @media (max-width: 768px) {
    min-width: 80%; /* For small screens, show almost one full card at a time */
    flex: 0 0 80%;
  }

  @media (max-width: 480px) {
    min-width: 100%; /* For mobile, show one full card at a time */
    flex: 0 0 100%;
  }
}
