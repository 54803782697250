.ad-section {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .ad-content {
    width: 96%;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;

    .ad-text {
      margin-bottom: 20px;

      h2 {
        font-size: 3rem;
        margin-bottom: 10px;
      }

      p {
        font-size: 1.5rem;
      }
    }

    .ad-button {
      .btn {
        padding: 10px 30px;
        background-color: #114084;
        color: white;
        font-size: 1.5rem;
        text-decoration: none;
        border-radius: 5px;
        transition: background-color 0.3s ease, transform 0.3s ease;

        &:hover {
          background-color: #3466aa;
          transform: scale(1.05);
        }
      }
    }
  }

  @media (max-width: 768px) {
    .ad-content {
      padding: 20px 10px;
      border-radius: 5px;

      .ad-text {
        h2 {
          font-size: 2.5rem;
        }

        p {
          font-size: 1.2rem;
        }
      }

      .ad-button {
        .btn {
          font-size: 1.2rem;
          padding: 8px 20px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .ad-content {
      padding: 15px;
      
      .ad-text {
        h2 {
          font-size: 2rem;
        }

        p {
          font-size: 1rem;
        }
      }

      .ad-button {
        .btn {
          font-size: 1rem;
          padding: 6px 16px;
        }
      }
    }
  }
}
