body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Kanit';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  max-width: 100%;
  overflow-x: hidden;
  background-color: #F1F1F1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  transform: scale(1);
  transform-origin: top left;
  width: 100vw;
  height: 100vh;
}

.kanit-thin {
  font-family: "Kanit", system-ui;
  font-weight: 100;
  font-style: normal;
}

.kanit-extralight {
  font-family: "Kanit", system-ui;
  font-weight: 200;
  font-style: normal;
}

.kanit-light {
  font-family: "Kanit", system-ui;
  font-weight: 300;
  font-style: normal;
}

.kanit-regular {
  font-family: "Kanit", system-ui;
  font-weight: 400;
  font-style: normal;
}

.kanit-medium {
  font-family: "Kanit", system-ui;
  font-weight: 500;
  font-style: normal;
}

.kanit-semibold {
  font-family: "Kanit", system-ui;
  font-weight: 600;
  font-style: normal;
}

.kanit-bold {
  font-family: "Kanit", system-ui;
  font-weight: 700;
  font-style: normal;
}

.kanit-extrabold {
  font-family: "Kanit", system-ui;
  font-weight: 800;
  font-style: normal;
}

.kanit-black {
  font-family: "Kanit", system-ui;
  font-weight: 900;
  font-style: normal;
}

.kanit-thin-italic {
  font-family: "Kanit", system-ui;
  font-weight: 100;
  font-style: italic;
}

.kanit-extralight-italic {
  font-family: "Kanit", system-ui;
  font-weight: 200;
  font-style: italic;
}

.kanit-light-italic {
  font-family: "Kanit", system-ui;
  font-weight: 300;
  font-style: italic;
}

.kanit-regular-italic {
  font-family: "Kanit", system-ui;
  font-weight: 400;
  font-style: italic;
}

.kanit-medium-italic {
  font-family: "Kanit", system-ui;
  font-weight: 500;
  font-style: italic;
}

.kanit-semibold-italic {
  font-family: "Kanit", system-ui;
  font-weight: 600;
  font-style: italic;
}

.kanit-bold-italic {
  font-family: "Kanit", system-ui;
  font-weight: 700;
  font-style: italic;
}

.kanit-extrabold-italic {
  font-family: "Kanit", system-ui;
  font-weight: 800;
  font-style: italic;
}

.kanit-black-italic {
  font-family: "Kanit", system-ui;
  font-weight: 900;
  font-style: italic;
}
