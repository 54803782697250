.product-detail-container {
    padding-top: 2%;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);


    .product-detail {
        display: flex;
        width: 100%;
        padding: 2rem;
        gap: 1rem;
        position: relative;

        // Desktop Styles
        .product-images {
            flex: 1;
            display: flex;
            gap: 2rem;
            position: sticky;
            top: 10%;
            padding-top: 2%;
            padding-bottom: 5%;
            align-self: flex-start;

            .thumbnail-images {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                margin-left: 5%;

                img {
                    width: 60px;
                    height: 60px;
                    cursor: pointer;
                    border-radius: 5px;
                    object-fit: cover;
                    transition: box-shadow 0.3s ease;

                    &.selected {
                        box-shadow: 0 0 0 3px #000;
                    }
                }
            }

            .main-image {
                flex-grow: 1;
                display: flex;

                img {
                    width: 100%;
                    height: auto;
                    max-width: 75vw; // Adapts to viewport width for scaling screens
                    max-height: 100vh; // Ensures the image doesn't overflow the screen height
                    object-fit:scale-down;
                    border-radius: 1px;
                }
            }

            @media (max-width: 768px) {
                display: none;

            }
        }

        .product-info {
            flex: 1;
            display: flex;
            flex-direction: column;

            h1 {
                font-size: 2rem;
                font-weight: bold;
                margin-bottom: 1rem;
            }

            .price {
                font-size: 1.5rem;
                color: #555;
                margin-top: -2rem;
                margin-bottom: 2rem;
            }

            .options {
                margin-top: -1rem;

                .gender-options,
                .size-options {
                    margin-bottom: 1.5rem;
                }

                h4 {
                    margin-bottom: 0.5rem;
                }

                .gender,
                .size,
                .color-dot-detailpage {
                    border: 2px solid transparent;
                    /* Set a transparent border initially */
                    padding: 1rem 1rem;
                    margin-right: 1rem;
                    cursor: pointer;
                    border-radius: 4px;
                    transition: all 0.3s ease;

                    /* Default background */
                }

                .gender.active,
                .size.active,
                .color-dot-detailpage.active {
                    background-color: #114084;
                    /* Change to your selected color */
                    color: white;
                    border: 2px solid #114084;
                    /* Active border, consistent size */
                }

                /* Specific styles for the color dots */
                .color-dot-detailpage {
                    -webkit-tap-highlight-color: transparent;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    height: 20px;
                    display: inline-block;
                    border-radius: 50%;
                    /* Circular dots */
                    /* Set the same border width */
                    transition: border 0.3s ease;

                }

                .color-dot-detailpage.active {
                    border: 2px solid #000;
                    /* Active state */
                }
            }

            .extra-info {
                margin-top: 2rem;

                p {
                    font-size: 1rem;
                    color: #555;
                }
            }
        }

        .product-description {
            width: 90%;
        }

        // Mobile Styles
        .product-images-mobile {
            display: none; // Hidden by default

        }

        @media (max-width: 768px) {
            flex-direction: column;
            padding: 1rem;
            padding-top: 18%;
            max-width: 100%;
            overflow-x: hidden;


            .product-images {
                display: none;
                position: relative;
            }

            .product-images-mobile {
                display: block;
                position: relative;
                padding-top: 0;
                width: auto;

                .swiper {
                    width: 100%;
                    height: 600px;
                    margin-left: -4%;
                    padding-right: 5%;
                    display: flex;
                    max-width: 100%;
                    overflow-x: hidden;
                    z-index: 50;
                }

                .swiper-slide {
                    display: flex;

                    img {
                        height: auto;
                        width: 100%;
                        object-fit: cover; // Use contain to prevent stretching
                    }
                }

                .swiper-pagination-bullet-active {
                    background-color: #000;
                }

                .carousel-container {
                    position: relative;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: auto;
                        object-fit: contain;

                    }

                    .carousel-button {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        background: rgba(0, 0, 0, 0.5);
                        border: none;
                        color: #fff;
                        font-size: 2rem;
                        padding: 0.5rem;
                        cursor: pointer;

                        &.left {
                            left: 0;
                        }

                        &.right {
                            right: 0;
                        }
                    }
                }
            }

            .product-info {
                margin-top: 2rem;

                h1 {
                    font-size: 1.5rem;
                }

                .price {
                    font-size: 1.2rem;
                }

                // Adjust other styles as needed for mobile
            }
        }
    }

    /* Suggested Products Section */
    .suggested-products {
        margin-top: 4rem;

        h2 {
            font-size: 1.8rem;
            margin-bottom: 1rem;
            margin-left: 8%;
        }

        .suggested-products-container {
            display: flex;
            align-items: center;
            position: relative;

            .slider-btn {
                background: none;
                border: none;
                font-size: 2rem;
                cursor: pointer;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 2;

                &.left {
                    left: 0;
                }

                &.right {
                    right: 0;
                }
            }

            .suggested-products-slider {
                display: flex;
                overflow-x: auto;
                scroll-behavior: smooth;
                padding: 1rem;
                scroll-snap-type: x mandatory;

                // Ensure the items don't exceed the viewport width
                .product-item {
                    flex: 0 0 auto;
                    width: 80%; // Adjust as needed
                    margin-right: 1rem;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .product-detail-container {
        max-width: 100%;
        overflow-x: hidden;
    }
}