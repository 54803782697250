/* Background gradient and centering the login box */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #0f0f0f, #3c3c3c);
  }
  
  /* Styling the login box */
  .login-box {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 300px;
    opacity: 0; /* Start invisible */
    animation: fadeIn 1s forwards; /* Fade-in animation */
  }
  
  /* Fade-in keyframes */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px); /* Optional: Add a slight movement */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Heading style */
  .login-box h2 {
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  /* Error message style */
  .error {
    color: red;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  /* Container for input fields */
  .input-container {
    margin-bottom: 20px;
    text-align: left;
  }
  
  /* Label styling */
  .input-container label {
    display: block;
    margin-bottom: 5px;
    font-family: 'Arial', sans-serif;
    color: #555;
  }
  
  /* Password container styling */
  .password-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  /* Input field styling */
  .input-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Arial', sans-serif;
  }
  
  .password-container input {
    width: 100%;
    padding-right: 40px; /* Add space for the eye icon */
  }
  
  /* Hide Edge's default eye icon */
  .password-container input::-ms-reveal {
    display: none;
  }
  
  /* Password toggle button styling */
  .password-toggle-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #333;
  }
  
  /* Button styling */
  .login_button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: white;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  /* Button hover effect */
  .login_button:hover {
    background-color: #555;
  }
  