.map-container {
  h1 {
    padding-left: 10%;
    font-size: 40px;
  }

  .product-map-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;

    .product-card-container {
      flex: 1;
      padding-left: 5%;
    }

    .image-container {
      flex: 2;
      position: relative;

      img{
        box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 20px;
      }

      .model-image {
        width: 100%;
        height: auto;
        /* Adjust height based on image aspect ratio */
        object-fit: cover;
        display: block;
      }

      .product-button {
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.4);
        border: 2px solid #114084;
        cursor: pointer;
        transform: translate(-50%, -50%);
        transition: background-color 0.3s ease, transform 0.3s ease;

        &:hover {
          background-color: rgba(0, 0, 0, 0.7);
        }
      }

      .hover-modal {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        transform: translateX(-50%);
        pointer-events: none;
        z-index: 10;
        animation: fadeIn 0.3s ease-in-out;

        p {
          margin: 0;
          font-size: 0.9rem;
        }
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;

      .product-card-container {
        margin-bottom: 1.5rem;
        margin-right: 10%;
        margin-left: 0;
      }

      .image-container {
        .product-button {
          width: 24px;
          height: 24px;
        }

        .hover-modal {
          padding: 8px;

          p {
            font-size: 0.8rem;
          }
        }
      }
    }

    @media (max-width: 480px) {
      .image-container {
        .product-button {
          width: 20px;
          height: 20px;
        }

        .hover-modal {
          padding: 6px;

          p {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }


  .switch {
    margin: 0 auto;
    --_switch-bg-clr: #005399;
    --_switch-padding: 4px;
    /* padding around button*/
    --_slider-bg-clr: rgba(43, 177, 255, 0.65);
    /* slider color unchecked */
    --_slider-bg-clr-on: rgba(43, 177, 255, 0.65);
    /* slider color checked */
    --_slider-txt-clr: #ffffff;
    --_label-padding: 1rem 2rem;
    /* padding around the labels -  this gives the switch it's global width and height */
    --_switch-easing: cubic-bezier(0.47,
        1.64,
        0.41,
        0.8);
    /* easing on toggle switch */
    color: white;
    width: fit-content;
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 9999px;
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    position: relative;
    isolation: isolate;
  }

  .switch input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .switch>span {
    display: grid;
    place-content: center;
    transition: opacity 300ms ease-in-out 150ms;
    padding: var(--_label-padding);
  }

  .switch::before,
  .switch::after {
    content: "";
    position: absolute;
    border-radius: inherit;
    transition: inset 150ms ease-in-out;
  }

  /* switch slider */
  .switch::before {
    background-color: var(--_slider-bg-clr);
    inset: var(--_switch-padding) 50% var(--_switch-padding) var(--_switch-padding);
    transition:
      inset 500ms var(--_switch-easing),
      background-color 500ms ease-in-out;
    z-index: -1;
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.3),
      0 1px rgba(255, 255, 255, 0.3);
  }

  /* switch bg color */
  .switch::after {
    background-color: var(--_switch-bg-clr);
    inset: 0;
    z-index: -2;
  }

  /* switch hover & focus */
  .switch:focus-within::after {
    inset: -0.25rem;
  }

  .switch:has(input:checked):hover>span:first-of-type,
  .switch:has(input:not(:checked)):hover>span:last-of-type {
    opacity: 1;
    transition-delay: 0ms;
    transition-duration: 100ms;
  }

  /* switch hover */
  .switch:has(input:checked):hover::before {
    inset: var(--_switch-padding) var(--_switch-padding) var(--_switch-padding) 45%;
  }

  .switch:has(input:not(:checked)):hover::before {
    inset: var(--_switch-padding) 45% var(--_switch-padding) var(--_switch-padding);
  }

  /* checked - move slider to right */
  .switch:has(input:checked)::before {
    background-color: var(--_slider-bg-clr-on);
    inset: var(--_switch-padding) var(--_switch-padding) var(--_switch-padding) 50%;
  }

  /* checked - set opacity */
  .switch>span:last-of-type,
  .switch>input:checked+span:first-of-type {
    opacity: 0.75;
  }

  .switch>input:checked~span:last-of-type {
    opacity: 1;
  }

}