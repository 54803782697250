.drop-container {
  user-select: none;
  text-wrap: nowrap;

  p {
    border-left: #3838387b 1px solid;
    padding-left: 10%;
  }

  a {
    padding-right: 5%;
    padding-left: 5%;
    margin-left: 2%;
    display: inline-block;
    position: relative;
    text-decoration: none;
    transition: color 0.3s ease;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      width: 0%;
      height: 2px;
      bottom: -1px;
      left: 50%;
      background-color: #114084;
      transition: width 0.3s ease, left 0.3s ease;
    }

    &:hover::before {
      width: 100%;
      left: 0;
    }
  }

  .dropdown {
    position: relative;
    display: inline-flex;
    width: 13rem;
    margin-top: -10%;
    z-index: 5;

    &:hover h1 {
      border-radius: 5px 5px 0 0;
    }

    &:hover li {
      padding: 10px;
      border-top: 1px solid #383838; // Adjusted color
      height: auto;
      overflow: auto;
      opacity: 1;
    }

    p {
      padding-top: 3%;
    }

    h1 {
      padding: 10px;
      background: transparent; // Darken #333 by 2%
      color: #8f8f8f; // Darken #EEE by 40%
      font-size: 16px;
      font-weight: normal;
      border: 5px;
      border-radius: 5px;
      width: 100%;
      cursor: pointer;
      transition: all 0.5s linear 0s;



      &:hover {
        color: #0e2aa8; // Darken #EEE by 40%
      }


    }

    ul {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      padding: 0 10px;
      background: #ffffff; // Darken #333 by 2%
      color: #484848; // Darken #EEE by 40%
      border: 0;
      width: 100%;
      height: 0;
      overflow: hidden;
      cursor: pointer;
      opacity: 0;
      transition-property: all, background-color;
      transition-duration: 0.2s, 0.4s;

      &:hover,
      &.selected {
        background-color: #114084; // Darken #333 by 4%
        color: #ffffff;
      }

      &:last-child {
        border-radius: 0 0 5px 5px;
      }

      &:before {
        content: '';
        margin-top: -2px;
        margin-right: 10px;
        display: inline-block;
        border-radius: 5px;
        vertical-align: middle;
        width: 16px;
        height: 16px;
      }

      &:nth-child(1) {
        transition-delay: 0s, 0s;

        &:before {
          background: transparent;
        }
      }

      &:nth-child(2) {
        transition-delay: 0.1s, 0s;

        &:before {
          background: transparent;
        }
      }

      &:nth-child(3) {
        transition-delay: 0.2s, 0s;

        &:before {
          background: transparent;
        }
      }
    }

  }

  .filter-options {
    position: fixed;
    top: 0;
    padding-top: 10%;
    right: -100%;
    /* Initially hidden off-screen */
    width: 300px;
    height: 100%;
    background-color: #f4f4f4;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: right 0.1s ease;
    z-index: 10;
    transition: right 0.5s ease;
    overflow-y: auto;
    max-height: 100%;
    max-width: 100%;
    overflow-x: hidden;

    &.open {
      right: 0;
      /* Slide the panel in when open */
    }

    .filter-option {
      padding: 20px;
      border-bottom: 1px solid #ddd;

      p {
        margin: 0;
        font-size: 16px;
      }
    }

    .filter-header {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      cursor: pointer;
      border-bottom: 1px solid #ddd;
      font-size: 1.2rem;

      .caret {
        font-size: 1.2rem;
        transition: transform 0.3s ease;
      }

      /* When the section is toggled open */
      &.open .caret {
        transform: rotate(180deg);
        /* Rotate caret upwards */
      }
    }

    .filter-content {
      max-height: 0;
      overflow: hidden;
      padding: 0 15px;
      transition: max-height 0.3s ease;

    }

    /* Show content when open */
    .filter-content.open {
      max-height: 100%;
      /* Adjust as necessary */
    }

    .filter-option {
      margin: 10px 0;

      label {
        display: flex;
        align-items: center;
        font-size: 1rem;
        cursor: pointer;

        input[type="checkbox"] {
          margin-right: 10px;
        }
      }
    }

    /* Color Filter Section - Avoid extra padding at the bottom */
    .color-options {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      margin-bottom: 30rem;
      /* Ensure there's enough space at the bottom of the colors */

      .color-dot {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        border: 1px solid #ddd;
      }
    }

    /* Apply padding-bottom only to the last section (or specific section) */
    .filter-option:last-child {
      padding-bottom: 2rem;
      /* Ensure scrollable space without affecting the color filter */
    }

    @media (max-width: 768px) {
      padding-top: 30%;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
    /* No opacity initially */
    backdrop-filter: blur(0px);
    /* No blur initially */
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    /* Prevent interaction when the overlay is hidden */
    transition: backdrop-filter 0.5s ease, opacity 0.5s ease;
    /* Smooth transition */
  }

  /* When filter is open */
  .overlay.show {
    background: rgba(0, 0, 0, 0.4);
    /* Darken background */
    backdrop-filter: blur(10px);
    /* Apply blur effect */
    opacity: 1;
    pointer-events: all;
    /* Allow interaction only when filter is open */
  }
}

.selected-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  margin-top: 1rem;
  margin-right: 20%;
  gap: 0.5rem;
  padding: 0.5rem;
  box-sizing: border-box;

  /* Scrollbar customization (optional) */
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }

  .filter-tag {
    display: flex;
    align-items: center;
    background-color: #e0e0e0;
    color: #333;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    cursor: pointer;
    white-space: nowrap;

    .remove-filter {
      margin-left: 8px;
      cursor: pointer;
      font-weight: bold;
    }

    &.color-tag {
      background-color: transparent;
      /* Use the actual color as background */
      border: 1px solid #ccc;
      padding: 0.5rem;
    }
  }

  .clear-filters {
    padding-right: 1%;
    padding-left: 1%;
    margin-left: 2%;
    display: inline-block;
    position: relative;
    text-decoration: none;
    transition: color 0.3s ease;
    border: none;
    color: #616161;
    cursor: pointer;
    z-index: 1;

    &:hover {
      color: #1c1c1c;
    }

    &::before {
      content: "";
      position: absolute;
      width: 0%;
      height: 2px;
      bottom: -1px;
      left: 50%;
      background-color: #114084;
      transition: width 0.3s ease, left 0.3s ease;
    }

    &:hover::before {
      width: 100%;
      left: 0;
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    width: 23rem;
  }

}