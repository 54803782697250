.selection-card-container {
    display: flex;
    justify-content: center; // Distribute space between cards
    width: 100%;
    gap: 20px; // Space between cards
    max-width: 1440px;
    padding-top: 20px;
    margin: 0 auto;

    @media (max-width: 768px) {
        justify-content: center; // Center the cards on smaller screens
    }

    .selection-card {
        position: relative;
        border-radius: 15px;
        overflow: hidden; // Prevents the zoomed image from overflowing
        height: 600px; // Adjusted height for better responsiveness
        cursor: pointer; // Indicates the card is interactive
        margin-bottom: 20px; // Space between rows
        transition: box-shadow 0.3s ease-in-out; // Smooth shadow transition

        &:hover {
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); // Add a subtle shadow on hover
        }

        @media (max-width: 768px) {
            width: 100%; // Full width on very small screens
            height: 300px; // Adjusted height for mobile
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover; // Ensures the image covers the entire card without distortion
            transition: transform 0.3s ease-in-out; // Smooth zoom transition
        }

        &:hover img {
            transform: scale(1.1); // Zooms in the image on hover
        }

        .card-text {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 1.5em;
            text-align: center;
            pointer-events: none;
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.5); // Improves text readability
        }
    }
}