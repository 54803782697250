.register-form {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
  }
  
  .register-form h2 {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  .register-form form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .register-form label {
    display: flex;
    flex-direction: column;
    font-family: 'Arial', sans-serif;
    color: #555;
  }
  
  .register-form input,
  .register-form select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Arial', sans-serif;
  }
  
  .name-fields,
  .contact-fields,
  .status-fields {
    display: flex;
    gap: 10px;
  }
  
  .name-fields label,
  .contact-fields label,
  .status-fields label {
    flex: 1;
  }
  
  .image-upload {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .image-preview {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .image-preview-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .crop-container {
    margin-top: 20px;
    text-align: center;
  }
  
  .register-form button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: white;
    font-family: 'Arial', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .register-form button:hover {
    background-color: #555;
  }
  
  .Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
  }
  
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }