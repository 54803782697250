.products-page {
  display: flex;
  max-width: 94%;
  position: relative;
  margin:auto;
  padding-bottom: 10%;
  justify-content: center;
  align-items: center;

  .product-grid {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    /* Default grid for larger screens */
    gap: 0.5%;
    padding-top: 0;
  }

  .sidebar {
    position: sticky;
    top: 25%;
    flex-shrink: 0;
  }

}

.dropdown-container {
  position: relative;
  padding-top: 5%;
  padding-bottom: 0;
  margin-left: 84%;
  z-index: 100;
  max-width: 100%;

  .dropdown-component{
    margin-left: -10%;
  }

  .showingname {
    width: 300px;
    margin-left: -500%;


    @media (max-width: 768px) {
      margin-left: 0;
    }
  }


}

@media (max-width: 1090px) {

  .dropdown-container {
    padding-top: 15%;
    padding-bottom: 0;

    .dropdown-component{
      margin-left: -50%;
    }

    .dropdown-mobilecheck {
      margin-left: -100%;
    }
  }

  .sidebar {
    width: 100%;
    /* Sidebar takes full width on mobile */
    margin-bottom: 20px;
    /* Add some spacing below the sidebar */
  }


}

@media (max-width: 768px) {
  .dropdown-container {
    padding-top: 30%;
    padding-bottom: 0;
    max-width: 100%;
    right: 50%;

    .dropdown-mobilecheck {
      margin-left: -100%;
    }

  }

  .products-page {
    padding-top: 10%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sidebar-container {
    display: none;
    /* Ensure the sidebar doesn't take up space on mobile */
  }

  .sticky-filter {
    position: relative;
    /* Ensure that sticky behavior does not cause issues */
  }

  .product-grid {
    grid-template-columns: repeat(2, 1fr);
    /* Two cards per row */
    max-width: 100%;
    margin-inline: auto;
    padding-top: 1%;
    padding-right: 0;
    padding-bottom: 20rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 480px) {
  .products-page {
    margin-left: auto;
    margin-right: auto;
  }


  .product-grid {

    grid-template-columns: repeat(4, 1fr);
    /* One card per row */
    max-width: 100%;
    margin-inline: auto;
    margin-left: -12%;
    margin-top: -10%;
    margin-right: 0;
    padding-right: 0;
  }
}

.load-more-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -5%;


  .load-more-btn {
    text-align: center;
    cursor: pointer;
    position: relative;
    background-color: none;
    font-size: 1.5rem;
    padding-bottom: 5%;
    color: #8b8b8b;
    border-top: #ababab 2px solid;
    padding-top: 1%;
    width: 80%;
    transition: color 0.3s ease;

    &:hover {
      color: #5b5b5b;
    }

  }

}